.form{
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    align-items: stretch !important;
}

.check{
    margin-top: 1em !important;
    margin-bottom: 1em !important;
}

.check a{
    cursor: pointer !important;
}

.password{
    text-align:center;
    padding-top:0.5em;
}