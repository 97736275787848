:root {
    --navbar-bg: #f7f7f7;
    --calendar-bg: #ffffff;
    --primary-color: #26546F;
    --selected-background-color: var(--primary-color);
    --text-color: #00031A;
    --selected-text-color: #ffffff;
    --button-size-height: 5vh;
    --button-size-width: 3vw;
    --navigation-height: 2.5em;
    --border-radius: 4px;
    --transition-speed: 0.2s;
    --body-bg: #f8f9fa;
}

a{
    color: var(--primary-color) !important;
}

body{
    background-color: var(--body-bg);
}

.form-check-input:checked,
.btn:hover,
.accordion-button:not(.collapsed),
.badge,
.bg-primary{
    background-color: var(--primary-color) !important;
    border: none !important;
    color: var(--selected-text-color) !important;
}

.accordion-button,
.accordion-button:not(.collapsed){
    color: var(--primary-color);
}

.accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>");
}


.logo {
    display: block;
    height: 10vh;
    padding: 0.8em;
    margin-left: 2em;
}

.exitbutton {
    margin-right: 1.5em;
    font-size: 1.5em;
    fill: var(--primary-color);
}

.content {
    transition: all var(--transition-speed) ease-in-out;
    background-color: var(--navbar-bg);
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: space-around !important;
    align-items: center !important;
    margin-bottom: 18vh !important;
    left: 0;
}

.btn-primary,
.btn-active,
.btn-check:checked,
.btn:active,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.btn {
    border: solid var(--primary-color) !important;
    color: var(--primary-color) !important;
}

/*.btn-outline-danger{*/
/*    border: solid red !important;*/
/*    color: red !important;*/
/*}*/

.btn-primary,
.btn-active,
.btn-check:checked,
.btn:active,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}