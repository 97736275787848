.navbar {
    background-color: var(--navbar-bg) !important;
    position: fixed !important;
    bottom: 0 !important;
    left:0 !important;
    width: 100% !important;
    z-index: 1 !important;
}

.button{
    background-color: var(--navbar-bg) !important;
    width: 50% !important;
    height: 10vh !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    border-radius: 0 !important;
    color: var(--primary-color) !important;
    cursor: pointer !important;
}

.button svg{
    display: block !important;
    height: 8vh !important;
    width: 8vw !important;
    fill: var(--primary-color);
}

.button:hover,
.button:active,
.button[aria-selected='true']{
    background-color: var(--primary-color) !important;
    color: var(--navbar-bg) !important;
}

.button:hover svg,
.button:active svg,
.button[aria-selected='true'] svg{
    fill: var(--navbar-bg) !important;
}

@media (max-height: 750px) {
    .button{
        font-size: 0.8rem !important;
    }
    
}