.navbar{
    background-color: var(--primary-color);
    position: fixed;
    left: 0;
    top: 0;
    padding: 0 3em;
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.navbar_logo{
    width: 5em;
    height: 5em;
    position: fixed;
    top:2em;
}

.navbar *{
    color: aliceblue;
}

.navbar_logout{
    font-size: 1.5em;
    cursor: pointer;
    position: fixed;
    bottom: 2em;
}

.content{
    position: absolute;
    top:0;
    right: 0;
    width: 80%;
    margin: 0;
    padding: 0;
}

.list_item{
    text-decoration: none;
    font-size: 1.5em;
    cursor: pointer;
}

.text{
    width: 90% !important;
}