.title{
    margin-bottom: 2vh;
    font-size: 1em !important;
}

.button{
    bottom: 11vh !important;
    right: 2em !important;
    width: 80% !important;
    font-size: 1.3em !important;
    height: fit-content !important;
    margin: 0.3em !important;
}

.button svg{
    vertical-align: text-bottom !important;
}

.button span{
    padding-left: 0.3em !important;
    font-size: 0.7em !important;
}

.select{
    height: 7vh !important;
    width: 95% !important;
    margin-top: 2.3vh !important;
    margin-bottom: 4.6vh !important;
    color: var(--text-color) !important;
    border: none !important;
}

.modal_footer{
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly !important;
}

.modal_button{
    margin: 0 5vw;
    width: 8em !important;
    height: 3em !important;
}
.modal_button svg{
    margin-right: 1vw;
}
