.title{
    margin-bottom: 2vh !important;
    font-size: 1em !important;
}

.toolbar{
    width: 95% !important;
    margin: 0 0 1rem 0 !important;
}

.accordion{
    width: 95% !important;
}