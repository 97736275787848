.container{
    height: 100% !important;
    position: absolute !important;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    align-items: center !important;
}


.logo{
    display: block;
    height: 15vh;
    padding: 0.8em;
}

.footer{
    bottom:2em !important;
    position: absolute;
}