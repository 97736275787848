.react-calendar {
    font-family: Arial, Helvetica, sans-serif;
    width: 95%;
}

.react-calendar__tile,
.react-calendar__month-view__days__day {
    background-color: var(--calendar-bg);
    border: none;
    color: var(--text-color);
    font-size: 1.5vh;
    height: var(--button-size-height);
    width: var(--button-size-width);
    line-height: var(--button-size-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-calendar__tile--active,
.react-calendar__month-view__days__day--selected {
    background-color: var(--selected-background-color);
    border-radius: var(--border-radius);
    color: var(--selected-text-color);
}

.react-calendar__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--navigation-height);
    background-color: var(--calendar-bg);
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    border: none;
    background: var(--calendar-bg);
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    border: none;
    background: transparent;
    font-size: 1.125em;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    background-color: var(--calendar-bg);
}

.react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color) !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}